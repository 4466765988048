@import url("https://fonts.googleapis.com/css2?family=Outfit&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

:root {
  --ion-font-family: "Outfit", sans-serif;
  --fd-light-color: #e6f8ff;
  --fd-primary-color: #0b7dbe;
  --fd-primary-color-rgb: 45, 162, 194;
  --fd-border-radius: 2px;
}

.rs-navbar-default .rs-dropdown .rs-dropdown-toggle {
  border-radius: 3px;
  color: #ccc;
}

.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover {
  background-color: #fafafa22 !important;
}

* {
  font-family: var(--ion-font-family) !important;
}

::-webkit-scrollbar {
  height: 7px !important;
  width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  background: #fafafa40;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(228, 228, 228, 0.329);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(196, 196, 196);
}
