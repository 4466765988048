:root {
  --rs-btn-primary-bg: #ff0000;
}

.rs-modal-content {
  border-radius: 3px !important;
}

.page--standard {
  padding: 4px;
  /* font-size: 16; */
}

.form-grid {
  margin-left: 0 !important;
  width: 100%;
}

.column--left {
  overflow-y: "auto";
  padding: 4px;
  /* padding-bottom: 0.725rem; */
}

.column--center {
  display: "flex";
  flex-direction: "column";
  padding: 4px;
  /* padding-bottom: 0.725rem; */
}

.column--right {
  padding: 4px;
  max-width: "360px";
}

.hide-on-mobile {
  display: initial;
}

@media (max-width: 480px) {
  .page--standard {
    padding: 2px;
  }

  .column--center {
    padding: 4px;
  }

  .hide-on-mobile {
    display: none;
  }
}

.rs-grid-container {
  width: 100% !important;
}

.vibration {
  animation: vibration 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  backface-visibility: hidden;
  padding: 0 20px;
}

@keyframes vibration {
  10%,
  90% {
    color: #fafafa;
    transform: rotateY(2deg);
    background-color: var(--fd-primary-color);
    /* background-color: #d62543; */
  }

  20%,
  80% {
    transform: rotateY(6deg);
    background-color: var(--fd-primary-color);
    /* background-color: #db2727; */
  }

  30%,
  50%,
  70% {
    transform: rotateY(15deg);
    color: #fafafa;
    background-color: var(--fd-primary-color);
    /* background-color: #db2727; */
    padding: 0 20px;
  }

  40%,
  60% {
    transform: rotateZ(10deg);
    /* background-color: #ddda15; */
  }
}
